import Home from "./components/home.vue";
import EventPage from "./components/eventPage.vue";
import CreateEventPage from "./components/createEventPage.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/eventPage/:id", component: EventPage },
  { path: "/createEvent", component: CreateEventPage }
];

export default routes;
