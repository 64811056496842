<template>
  <FormulateForm
    @submit="
      submit({ id: id, name: name, date: date, description: description })
    "
  >
    <FormulateInput
      type="number"
      name="id"
      v-model="id"
      label="ID"
      validation="required"
    />
    <FormulateInput
      type="text"
      name="name"
      v-model="name"
      label="Name"
      help="Name des Events"
      validation="required"
    />
    <FormulateInput
      type="text"
      name="date"
      v-model="date"
      label="Datum"
      validation="required"
    />
    <FormulateInput
      type="textarea"
      name="description"
      v-model="description"
      label="Beschreibung"
      validation="required"
    />
    <FormulateInput label="Erstellen" type="submit" />
  </FormulateForm>
</template>

<script>
import axios from "axios";

export default {
  data: function() {
    return { id: "", name: "", date: "", description: "" };
  },
  methods: {
    submit: function(event) {
      axios
        .post("dbActions.php", {
          action: "insertEvent",
          event: event
        })
        .then(() => {
          console.log("success");
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style></style>
