<template>
  <div>
    <h1>Event Page</h1>
    <h6 v-if="isLoading">lädt...</h6>
    <table
      border="1"
      width="80%"
      style="border-collapse: collapse;"
      v-if="!isLoading && !hasError"
    >
      <tr>
        <td>{{ event.id }}</td>
        <td>{{ event.name }}</td>
        <td>{{ event.date }}</td>
        <td>{{ event.description }}</td>
      </tr>
    </table>

    <h6 v-if="hasError">Fehler</h6>

    <button v-on:click="$router.go(-1)">Zurück</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function() {
    return {
      event: {},
      isLoading: true,
      hasError: false
    };
  },
  methods: {
    getEventById: function() {
      axios
        .post("dbActions.php", {
          action: "getEventById",
          id: this.$route.params.id
        })
        .then(response => {
          console.log(response);
          this.event = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.hasError = true;
        });
    }
  },
  created: function() {
    this.getEventById();
  }
};
</script>

<style></style>
