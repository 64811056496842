<template>
  <div>
    <table border="1" width="80%" style="border-collapse: collapse;">
      <tr>
        <th>ID</th>
        <th>Name</th>
      </tr>

      <tr v-for="event in events" v-bind:key="event.id">
        <td>{{ event.id }}</td>
        <td>{{ event.name }}</td>
        <td>
          <button v-on:click="push(event.id)">
            Details
          </button>
        </td>
      </tr>
    </table>

    <table></table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    events: { type: Array }
  },
  methods: {
    allRecords: function() {
      axios
        .get("dbActions.php", {
          action: "fetchAllData"
        })
        .then(response => {
          console.log(response);
          this.events = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    push: function(id) {
      this.$router.push("/eventPage/" + id);
    }
  },
  created: function() {
    this.events = [
      {
        id: 1,
        name: "Test"
      }
    ];
    this.allRecords();
  }
};
</script>

<style lang="css" scoped>
.section {
  padding-top: 10%;
  padding-bottom: 10%;
}
</style>
